import { QItemSection as _component_q_item_section } from 'quasar/src/index.dev.js';
import { QItem as _component_q_item } from 'quasar/src/index.dev.js';
import { QItemLabel as _component_q_item_label } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, unref as _unref } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

import fuzzysort from 'fuzzysort'
import {QSelect as QSelect} from 'quasar/src/index.dev.js';
import {QSelectProps as QSelectProps} from 'quasar/src/index.dev.js';

import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { NUM_MEMES_PER_PAGE, SEARCH_THRESHOLD } from '@/common/Constants'
import { PreparedOptionMemeTemplate } from '@/web/client/store/MemeTemplate/PreparedOptionMemeTemplate'
import { useMemeTemplateStore } from '@/web/client/store/MemeTemplate/useMemeTemplateStore'


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayoutHeaderSearchBar',
  emits: ["selectedMeme"],
  setup(__props, { emit: __emit }) {

const searchQuery = ref('')
const getHighlight = (origString: string) => {
    const match = fuzzysort.single(searchQuery.value, origString)
    if (!match) {
        return origString
    }

    const highlight = match.highlight()
    if (!highlight) {
        return origString
    }

    return highlight
}

const memeTemplateStore = useMemeTemplateStore()
const searchResults = ref<Array<PreparedOptionMemeTemplate>>([])
const onFilter: QSelectProps['onFilter'] = (query, update) => {
    update(() => {
        searchQuery.value = query

        if (!query) {
            searchResults.value = memeTemplateStore.preparedOptionsMemeTemplate.slice(0, NUM_MEMES_PER_PAGE)
            return
        }

        const results = fuzzysort.go(query, memeTemplateStore.preparedOptionsMemeTemplate, {
            keys: ['namePrepared', 'membersPrepared'],
            threshold: SEARCH_THRESHOLD,
        })

        searchResults.value = results.slice(0, NUM_MEMES_PER_PAGE).map((result) => result.obj)
    })
}

const emit = __emit
const router = useRouter()
const onUpdate = (selectedOption: PreparedOptionMemeTemplate) => {
    emit('selectedMeme')

    if (document.activeElement && document.activeElement instanceof HTMLInputElement) {
        document.activeElement.blur()
    }

    console.info(`[MainLayoutHeaderSearchBar] Navigate to ${selectedOption.route}`)
    void router.push(selectedOption.route)
}

return (_ctx: any,_cache: any) => {
  
  
  

  return (_openBlock(), _createBlock(_unref(QSelect), {
    "use-input": "",
    standout: "",
    label: "Search for a meme template...",
    "bg-color": "primary",
    "label-color": "white",
    spellcheck: "false",
    "input-debounce": "250",
    options: searchResults.value,
    "model-value": null,
    "onUpdate:modelValue": onUpdate,
    onFilter: onFilter
  }, {
    "no-option": _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { class: "text-grey" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" No results ")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    option: _withCtx((scope) => [
      _createVNode(_component_q_item, _mergeProps({
        title: `Create new ${scope.opt.name} meme`,
        class: "search-option"
      }, scope.itemProps), {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { avatar: "" }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: scope.opt.iconUrl,
                alt: scope.opt.name,
                width: "40",
                height: "40",
                style: _normalizeStyle({
                            'background': scope.opt.background,
                            'object-fit': 'cover',
                        })
              }, null, 12, _hoisted_1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    innerHTML: getHighlight(scope.opt.name)
                  }, null, 8, _hoisted_2)
                ]),
                _: 2
              }, 1024),
              (scope.opt.members)
                ? (_openBlock(), _createBlock(_component_q_item_label, {
                    key: 0,
                    caption: ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: getHighlight(scope.opt.members)
                      }, null, 8, _hoisted_3)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1040, ["title"])
    ]),
    _: 1
  }, 8, ["options"]))
}
}

})