import { QBtn as _component_q_btn } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header-logo" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "search" }

import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { APP_NAME } from '@/common/Constants'
import holomemesLogo from '@/web/client/assets/img/holomemes.svg'
import { useMemeTemplateStore } from '@/web/client/store/MemeTemplate/useMemeTemplateStore'
import MainLayoutHeaderSearchBar from './MainLayoutHeaderSearchBar.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayoutHeader',
  setup(__props) {

const isHeaderItemsExpanded = ref(false)
const closeHeaderItems = () => {
    isHeaderItemsExpanded.value = false
}

const memeTemplateStore = useMemeTemplateStore()
const router = useRouter()

const goRandomMeme = () => {
    const path = memeTemplateStore.getRandomMemeRoute(router.currentRoute.value)
    console.info(`[goRandomMeme] Navigate to meme:${path}`)
    void router.push(path)
}

const goNextMeme = () => {
    const path = memeTemplateStore.getNextMemeRoute(router.currentRoute.value)
    console.info(`[goNextMeme] Navigate to ${path}`)
    void router.push(path)
}

return (_ctx: any,_cache: any) => {
  
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_btn, {
        icon: "menu",
        class: "expand-btn",
        flat: "",
        padding: "0",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isHeaderItemsExpanded.value = !isHeaderItemsExpanded.value))
      }),
      _createVNode(_component_router_link, {
        to: "/",
        class: "logo",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (closeHeaderItems()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", { innerHTML: _unref(holomemesLogo) }, null, 8, _hoisted_2),
          _createTextVNode(" " + _toDisplayString(_unref(APP_NAME)), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["header-items", {
                expanded: isHeaderItemsExpanded.value
            }])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(MainLayoutHeaderSearchBar, {
          onSelectedMeme: _cache[2] || (_cache[2] = ($event: any) => (closeHeaderItems()))
        })
      ]),
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => {goRandomMeme(); closeHeaderItems()})
      }, " Random Meme "),
      _createElementVNode("button", {
        onClick: _cache[4] || (_cache[4] = ($event: any) => {goNextMeme(); closeHeaderItems()})
      }, " Next Meme 👏 "),
      _createVNode(_component_router_link, {
        to: "/faq",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (closeHeaderItems()))
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode(" FAQ ")
        ])),
        _: 1
      })
    ], 2)
  ]))
}
}

})