import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { APP_NAME } from '@/common/Constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayoutFooter',
  setup(__props) {

const copyright = `${new Date().getFullYear()} ${APP_NAME}`

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("p", null, " © " + _toDisplayString(copyright)),
    _cache[0] || (_cache[0] = _createElementVNode("p", null, " This is a fansite and is not affiliated with Hololive ", -1))
  ]))
}
}

})